import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <text x="33.7" y="70" fill="currentColor" fontSize="55px" fontFamily="Consolas, serif">
      MB
    </text>
  </svg>
);

export default IconLoader;
